import { useContext, useEffect, useState } from 'react';
import { Coins } from '@terra-money/terra.js';
import './Projects.css'
import { UserDenied } from '@terra-money/wallet-provider';
import { ThemeContext } from '../../contexts/theme';
import BroadcastLayer from '../../utils/Layers/BroadcastLayer';
import ErrorMessage from '../../utils/Layers/ErrorMessage';
import { ValidatorAddress } from '../../utils/BackendApi';

const Delegate = ({miataClients, walletAddress, connectedWallet}) => {
  const [{ themeName }] = useContext(ThemeContext)
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [isBroadcasting, setBroadcasting] = useState(false);
  const [modalText, setModalText] = useState("Please wait …");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorText, setErrorText] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const [delegated, setDelegated] = useState(0);
  const [allDelegated, setAllDelegated] = useState(0);
  const [delegateShare, setDelegateShare] = useState(0);
  const [autocompoundEnabled, setAutocompoundEnabled] = useState(false);
  const [grants, setGrants] = useState([]);
  const [grantVals, setGrantVals] = useState([]);
  const [rewards, setRewards] = useState(null);

  const handleOpenError = () => setOpenErrorMessage(true);
  const handleCloseError = () => setOpenErrorMessage(false);

  const contract = "terra18m0tftxhlp86xrdgnvucp8k6px7k5kpwtl3pwr";
  const stakingManager = "terra1kygtatakw7lc0pscmwxj74h59yam8y4sxuwm02";

  useEffect(() => {
    const fetchDelegated = async () => {
      if(!miataClients || !walletAddress || !connectedWallet) {
        return;
      }
      const isConnected = !(!connectedWallet || !connectedWallet.network.chainID || !miataClients || !miataClients[connectedWallet.network.chainID] || !walletAddress);

      let del_result = null;
      const chainID = isConnected ? connectedWallet.network.chainID : 'columbus-5';

      if(isConnected) {
        del_result = await miataClients[chainID].getDelegated(walletAddress);
        if(del_result && del_result.balance) {
          setDelegated(del_result.balance?.amount);
        } else {
          setDelegated(0);
        }
      } else {
        setDelegated(0);
      }
      
      const val_result = await miataClients[chainID].getAllDelegated();
      if(val_result && val_result.tokens) {
        const share = del_result?.balance?.amount ? (del_result.balance?.amount / val_result.tokens) * 100 : 0;
        setAllDelegated(val_result.tokens);
        setDelegateShare(share);
      } else {
        setAllDelegated(0);
        setDelegateShare(0);
      }

      miataClients[chainID].getGrants(walletAddress, stakingManager).then((result) => {
        console.log('grants ', result[0]);
        result[0].forEach((el) => {
          if (el.authorization && el.authorization.authorization_type && el.authorization.authorization_type === 1) {
            console.log('allow', el.authorization.allow_list.address);
            setGrantVals(el.authorization.allow_list.address);
          }
        });
        setGrants(result[0]);
      }).catch((error) => {
        console.log('getGrants error ', error)
      });

      miataClients[chainID].getStakingRewards(walletAddress).then((result) => {
        if(!result.rewards) {
          setRewards(0);
          return;
        }
        console.log('rewards ', result.rewards)
        const del_rewards = result.rewards[ValidatorAddress] || new Coins();
        setRewards(del_rewards.get('uluna')?.amount || 0);
      }).catch((error) => {
        console.log('getGrants error ', error)
      })

      miataClients[chainID].getAutocompoundStatus(contract, walletAddress).then((status) => {
        setAutocompoundEnabled(status);
      }).catch((error) => {
        console.log('getGrants error ', error)
      });
    };
    fetchDelegated();
  }, [connectedWallet, miataClients, reloadData, walletAddress])

  const handleRevoke = () => {
    setModalText(`Revoking autocompound permissions …`);
    setBroadcasting(true);
    const newVals = grantVals.filter((el) => el !== ValidatorAddress);

    if(newVals.length > 0) {
      miataClients[connectedWallet.network.chainID].grantValidatorAuthz(walletAddress, stakingManager, newVals).then(async (result) => {
        console.log('revoke result ', result);
        setReloadData(!reloadData);
      }).catch((error) => {
        handleError(error);
      }).finally(() => {
        setBroadcasting(false);
      });
    } else {
      miataClients[connectedWallet.network.chainID].revokeValidatorGrant(walletAddress, stakingManager).then(async (result) => {
        console.log('revoke result ', result);
        setReloadData(!reloadData);
      }).catch((error) => {
        handleError(error);
      }).finally(() => {
        setBroadcasting(false);
      });
    }
  }

  const handleGrant = () => {
    setModalText(`Granting autocompound permissions …`);
    setBroadcasting(true);
    miataClients[connectedWallet.network.chainID].grantValidatorAuthz(walletAddress, stakingManager, [ValidatorAddress]).then(async (result) => {
      console.log('grant result ', result);
      setReloadData(!reloadData);
    }).catch((error) => {
      handleError(error);
    }).finally(() => {
      setBroadcasting(false);
    });
  }

  const handleAutocompound = (enabled) => {
    setModalText(`Setting autocompound to ${enabled ? "enabled" : "disabled"} …`);
    setBroadcasting(true);
    miataClients[connectedWallet.network.chainID].setAutoCompound(contract, walletAddress, enabled).then(async (result) => {
      console.log('setAutocompound result ', result);
      setReloadData(!reloadData);
    }).catch((error) => {
      handleError(error);
    }).finally(() => {
      setBroadcasting(false);
    });
  }

  const handleError = (error) => {
    setErrorTitle("Error");
    setErrorText("An error occured. Please try again.");
    console.log(typeof error, error);
    
    if(error instanceof UserDenied) {
      setErrorTitle("Transaction denied");
      setErrorText("The transaction was denied by the user.");
    } else if(typeof error === 'object') {
      if(error.response && error.response.data && error.response.data.code) {
        switch(error.response.data.code) {
          /*case 3:
            setErrorTitle("Insufficient Funds");
            setErrorText("You do not have enough funds to perform this transaction. Please also make sure you have enough funds in your wallet to cover the transaction fee.");
            break;*/
          case 5:
            setErrorTitle("Wallet not found");
            setErrorText("The connected wallet could not be found. Please be aware that a wallet only really exists once it has made its first transaction.");
            break;
          default:
            if(error.toString() === '[UserDenied]' || error.toString() === '[WebExtensionUserDenied]') {
              setErrorTitle("Transaction denied");
              setErrorText("The transaction was denied by the user.");
            } else {
              setErrorText(error.response.data.message);
            }
        }
      } else if(error.toString() === '[UserDenied]' || error.toString() === '[WebExtensionUserDenied]') {
        setErrorTitle("Transaction denied");
        setErrorText("The transaction was denied by the user.");
      } else {
        setErrorText(error.toString());
      }
    } else {
      setErrorText(error);
    }
    handleOpenError();
  }


  return (
    <>
    <BroadcastLayer title="Broadcasting…" text={modalText} openModal={isBroadcasting} themeName={themeName} />
    <ErrorMessage title={errorTitle} text={errorText} openModal={openErrorMessage} handleClose={handleCloseError} themeName={themeName} />
    {/*<section id='delegate' className='section projects'>
    <h2 className='section__title'>
      Delegate with HappyCattyCrypto Validator
    </h2>
    <p>If you want to delegate your LUNC with HCC, just go to <a href='https://station.terra.money/validator/terravaloper15ahd0dg9qwkg5tjmkn7fm6sdrpwa47m50l4zrg' target='_blank' rel="noopener noreferrer">Terra Station</a> or do it right here.</p>
    <p>You currently have <strong>{(delegated / 1000000).toLocaleString()} LUNC</strong> delegated with the validator. That is <strong>{delegateShare.toFixed(2)}%</strong> of the total <strong>{(allDelegated / 1000000).toLocaleString()} LUNC</strong> delegated.</p>
    </section>
    <section id='yournft' className='section projects'>
      <div className='section__content nft-section'>
        <div className='plain-card'>
        <div className='nft-card__content'>
        <h3 className='nft-card__title'>Delegate</h3>
        <label htmlFor='delegate-amount'><span style={{display: "inline-block", marginRight: ".5rem"}}>Amount </span>
        <input type='number' placeholder='Amount' id='delegate-amount' className='' /> LUNC</label>
        <button type="button" className="btn btn--outline" style={{marginTop: "0.5rem"}} onClick={() => {
          const amount = document.getElementById(`delegate-amount`).value;
          setModalText(`Delegating …`);
          setBroadcasting(true);
          miataClients[connectedWallet.network.chainID].delegate(amount).then(async (result) => {
            console.log('delegate result ', result);
            setReloadData(!reloadData);
            document.getElementById(`delegate-amount`).value = "";
          }).catch((error) => {
            handleError(error);
          }).finally(() => {
            setBroadcasting(false);
          });
        }}>Delegate</button>
        </div>
        </div>
      </div>
    </section>*/}
    <h2 className='section__title'>
      Autocompound
    </h2>
    <p>You can autocompound your LUNC rewards from the HappyCattyCrypto validator. This will automatically claim your rewards and re-delegate them to the validator.</p>
    <p>Autocompound is currently <strong>{autocompoundEnabled ? "enabled" : "disabled"}</strong>.
    {(grants && grants.length > 0) && (autocompoundEnabled ? <button type="button" className='btn btn--outline' onClick={() => handleAutocompound(false)}>Disable</button> : <button type="button" className='btn btn--outline' onClick={() => handleAutocompound(true)}>Enable</button>)}</p>
    <p>You currently have accumulated <strong>{rewards ? (rewards / 1000000).toLocaleString(undefined, {maximumFractionDigits: 1}) : "0"} LUNC</strong> in rewards.</p>
    {(grantVals && grantVals.length > 0 && grantVals.includes(ValidatorAddress)) ? (
      <>
      <p>You have granted autocompound permissions to the validator.</p>
      <button type="button" className='btn btn--outline' onClick={() => handleRevoke()}>Revoke</button>
      </>
    ) : (
      <>
      <p>You have not granted autocompound permissions to the validator.</p>
      <button type="button" className='btn btn--outline' onClick={() => handleGrant()}>Grant</button>
      </>
    )}
    </>
  )
}

export default Delegate
