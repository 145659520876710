import { useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ManagerWallet, ValidatorAddress } from "../utils/BackendApi";
import { ThemeContext } from "../contexts/theme";

const AuthZ = ({miataClients, walletAddress, connectedWallet}) => {


    const [{ themeName }] = useContext(ThemeContext)
    const [openErrorMessage, setOpenErrorMessage] = useState(false);
   
    const [grants, setGrants] = useState([]);

    const handleOpenError = () => setOpenErrorMessage(true);
    const handleCloseError = () => setOpenErrorMessage(false);
    
    useEffect(() => {
        const fetchData = async () => {
          if(!miataClients || !walletAddress || !connectedWallet) {
            return;
          }
         
          miataClients[connectedWallet.network.chainID].getGrants(walletAddress, ManagerWallet).then((data) => {
            console.log('grants ', data[0]);
            setGrants(data[0]);
          });
      };
        fetchData();
    
      }, [miataClients, walletAddress]);

    const handleGrant = () => {
        if(!miataClients || !ValidatorAddress || !connectedWallet) {
            alert('Validator not set for this wallet. Please set validator first.');
            return;
        }
        miataClients[connectedWallet.network.chainID].grantStaking(walletAddress, ManagerWallet, ValidatorAddress).then((data) => {
            console.log('grantdone ', data);
            window.location.reload();
        }).catch((error) => {
            if(typeof error === 'object') {
            if(error.response && error.response.data && error.response.data.code) {
                error = error.response.data.message;
            } else {
                error = error.toString();
            }
            }
            if(error === '[UserDenied]') {
            alert('Transaction denied by user.');
            } else {
            alert(error);
            }
        });
        };

    const handleRevoke = (grantee, msgtype) => {
        if(!miataClients || !connectedWallet) {
            alert('Validator not set for this wallet. Please set validator first.');
            return;
        }

        miataClients[connectedWallet.network.chainID].revokeGrant(walletAddress, grantee, msgtype).then((data) => {
            console.log('grantdone ', data);
            window.location.reload();
        }).catch((error) => {
            if(typeof error === 'object') {
            if(error.response && error.response.data && error.response.data.code) {
                error = error.response.data.message;
            } else {
                error = error.toString();
            }
            }
            if(error === '[UserDenied]') {
            alert('Transaction denied by user.');
            } else {
            alert(error);
            }
        });
        }
        
    return (
        <>
        <section id='authz' className='section projects'>
          
        <Typography variant='h5' color='secondary'>
            {walletAddress}
        </Typography>

        <Typography variant="body1" align="center" color="white">
            <button type="button" className="btn btn--outline" onClick={() => {handleGrant(false)}}>
              Grant AuthZ
            </button>
        </Typography>
        {(grants && grants.length > 0) && (
        <>
        <br />
        <br />
            {grants.map((el) => (
            <>
            {(el.authorization && el.authorization.authorization_type && el.authorization.authorization_type === 1) && (
                <>
                <Typography variant='body2' color='secondary'><strong>Grant:</strong> DELEGATION to {el.authorization.allow_list.address[0]} until {el.expiration.toLocaleString()} </Typography>  
                <button type="button" className="btn btn--outline" onClick={() => {handleRevoke(ManagerWallet, '/cosmos.staking.v1beta1.MsgDelegate')}}>Revoke</button>
                </>
            )}
            {(el.authorization && el.authorization.spend_limit) && (
                <>
                <Typography variant='body2' color='secondary'><strong>Grant:</strong> SEND Coins until {el.expiration.toLocaleString()} </Typography>  
                <button type="button" className="btn btn--outline" onClick={() => {handleRevoke(ManagerWallet, '/cosmos.bank.v1beta1.MsgSend')}}>Revoke</button>
                </>
            )}
            {(el.authorization && el.authorization.msg) && (
                <>
                <Typography variant='body2' color='secondary'><strong>Grant:</strong> {el.authorization.msg.replace(/^.*\.(Msg.*?)$/, '$1')} until {el.expiration.toLocaleString()} </Typography>  
                <button type="button" className="btn btn--outline" onClick={() => {handleRevoke(ManagerWallet, el.authorization.msg)}}>Revoke</button>
                </>
            )}
            </>
            ))}
        </>
        )}
        
        </section>
        </>
      )
    }
    
    export default AuthZ;
      