import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import '../../App.css'

//import anime from "animejs";

const BroadcastLayer = ({ title, text, openModal, themeName }) => {

  return (
    <Modal
        className={`layer-modal ${themeName}`}
        sx={{ zIndex: 99000 }}
        open={openModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            className="layer-box"
          >
            <Typography
              color="secondary"
              variant="h6"
              component="div"
            >
              <Typography variant="h6" component="span" mt={-0.2}>
                {title}
              </Typography>
            </Typography>
            
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              height={150}
              >
            <Typography
              // This is global styles
              variant="body2"
              component="p"
              lineHeight={2}
              pr={2.5}
            >
              {text}
            </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
  );
};

export default BroadcastLayer;
