import React from 'react';
import ReactDOM from 'react-dom';
import { WalletProvider, getChainOptions } from '@terra-money/wallet-provider';
import App from './App'
import { ThemeProvider } from './contexts/theme'
import './index.css'

// eslint-disable jsx-props-no-spreading

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
  <React.StrictMode>
      <WalletProvider {...chainOptions}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </WalletProvider>
  </React.StrictMode>,
  document.getElementById('root')
  )
}).catch((err) => {
  console.log('load err', err);
  // eslint-disable-next-line no-alert
  window.alert('Error try again.');
});
