const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://hccfactory.com',
  title: 'HCC Factory',
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  /*{
    name: 'LUNCPenguins',
    nav: 'Penguins',
    description:
      'The OG Penguin NFT project',
    stack: ['NFT', 'Staking', 'Pixel Art'],
    telegram: 'https://t.me/LUNCpenguinsNFT',
    twitter: 'https://twitter.com/LUNC_Penguins',
    page: '/luncpenguins'
  },*/
  /*{
    name: 'The Hatchery',
    nav: 'Hatchery',
    description:
      'The new innovative NFT project incorporating the OG Penguins',
    stack: ['NFT', 'Staking', 'Innovation'],
    telegram: 'https://t.me/LUNCpenguinsNFT',
    twitter: 'https://twitter.com/LUNC_Penguins',
    page: '/hatchery'
  },*/
  {
    name: 'LUNCPenguins',
    nav: 'LUNCPenguins',
    description:
      'Join the Penguin Clan and earn rewards',
    stack: ['NFT', 'Staking', 'Innovation'],
    telegram: 'https://t.me/LUNCpenguinsNFT',
    twitter: 'https://twitter.com/LUNC_Penguins',
    page: '/clandom'
  },
  /*{
    name: 'Project LUNCBurn',
    nav: 'LUNCBurn',
    description:
      'A NFT project dedicated to burning LUNC',
    stack: ['NFT', 'Staking', 'Burning'],
    telegram: 'https://t.me/HappyCattyCryptoClubHouse',
    twitter: 'https://twitter.com/ProjectLUNCBurn',
    page: '/luncburn'
  },
  {
    name: 'Penguin Island',
    nav: 'Penguin Island',
    description:
      'An exclusive Island for Penguin NFT holders',
    stack: ['NFT', 'Island', 'Exodus'], 
    telegram: 'https://t.me/LUNCpenguinsNFT',
    twitter: 'https://twitter.com/LUNC_Penguins',
    page: '/penguinisland'
  },*/
  {
    name: 'Autocompound with HCC',
    nav: 'Auto-Compound',
    description:
      'Delegate with the HappyCattyCrypto Node and auto-compound your rewards',
    stack: ['Staking', 'Rewards', 'Validator'], 
    telegram: 'https://t.me/HappyCattyCryptoClubHouse',
    twitter: 'https://twitter.com/HappyCatKripto',
    page: '/delegate'
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Redux',
  'SASS',
  'Material UI',
  'Git',
  'CI/CD',
  'Jest',
  'Enzyme',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: null,//'happycattycrypto@gmail.com',
}

export { header, projects, skills, contact }
