import { Link } from 'react-router-dom'
import { header } from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import './Header.css'

const Header = () => {
  const { homepage, title } = header

  return (
    <header className='header center'>
      <h3>
        <Link to="/#" className='link'>
          <img src="/assets/img/hccfactory.png" alt="HCC Factory" width="50" style={{verticalAlign: "middle", marginRight: ".5rem" }} />
          {title}
        </Link>
      </h3>
      <Navbar />
    </header>
  )
}

export default Header
