
const NFTCard = ({card, collection, hatchable, handleSelect, handleDeselect, selected, selectedItems}) => {
    const useSelected = selected || (selectedItems && selectedItems.find((item) => item.token_id === card.token_id));

    return (
        <div className={hatchable ? (useSelected ? "nft-card hatchable selected" : "nft-card hatchable") : "nft-card"}>
            <div className='nft-card__image'>
                {(hatchable || useSelected) ? (
                    <a href="/#" onClick={(e) => { e.preventDefault(); if(useSelected) { handleDeselect(card, e.target); } else { handleSelect(card, e.target); } return false;}} target="_blank" rel="noreferrer"><img src={card.image_preview} alt={card.name} /></a>
                ) : (
                    <a href={`https://miata.io/collections/${collection}/nft/${card.token_id}`} target="_blank" rel="noreferrer"><img src={card.image_preview} alt={card.name} /></a>
                )}
            </div>
            <div className='nft-card__content'>
                <h3 className='nft-card__title'>{card.name}</h3>
                {(card.clan) ? (
                    <>
                    <p className='nft-card__text'>Clan: {card.clan}<br />Position: {card.position}<br />Rarity: {card.rarity}</p>
                    </>
                ) : (
                    <>
                    <p className='nft-card__text'>Rarity: {card.rarity}</p>
                    </>
                )}
                <small><a href={`https://miata.io/collections/${collection}/nft/${card.token_id}`} target="_blank" rel="noreferrer">View on Miata</a></small>
                {/* <p className='nft-card__text'>{card.description}</p> */}
            </div>
        </div>
    )
}

export default NFTCard