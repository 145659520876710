import { AuthInfo, AuthorizationGrant, Coin, Coins, CreateTxOptions, ExtensionOptions, Fee,  GenericAuthorization,  LCDClient, ModeInfo, Msg, MsgDelegate, MsgExecuteContract, MsgGrantAuthorization, MsgRevokeAuthorization, SignDoc, SignMode, SignatureV2, SignerInfo, StakeAuthorization, StakeAuthorizationValidators, Tx, TxBody, TxInfo, Wallet } from "@terra-money/terra.js";
import { ConnectType, ConnectedWallet } from "@terra-money/wallet-provider";
import { ManagerWallet, ValidatorAddress } from "../utils/BackendApi";
//import { ConnectedWallet } from "@terra-money/wallet-types";

function isConnectedWallet(x: Wallet | ConnectedWallet): x is ConnectedWallet {
  if(!x) {
    return false;
  }
  return typeof (x as Wallet).key === "undefined";
};

async function getTaxAmount(amount?: Coins, lcd?: LCDClient) {
  if(!amount || !lcd) {
    return 0;
  }

  const taxRate = await(
    await fetch(lcd.config.URL + '/terra/treasury/v1beta1/tax_rate', {
      redirect: 'follow'
    })
  ).json();

  const amountUluna = amount.get('uluna');

  if(!amountUluna) {
    return 0;
  }

  const taxInUluna = amountUluna.amount.toNumber() * taxRate.tax_rate;

  return taxInUluna;
};

async function getTransactionData(messages: any, senderAddress: string, funds: Coins, client: LCDClient, fcdUrl: string, isClassic: boolean): Promise<ExtensionOptions> {
  let tax = 0;
  if(isClassic) {
    tax = await getTaxAmount(funds, client);
  }

  const gasPrices = await(
    await fetch(fcdUrl + '/v1/txs/gas_prices', {
      /*redirect: 'follow',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin':'https://miata.io'
      }*/
    })
  ).json();

  const gasAdjustment = 2.5;
  const gasPricesCoins = new Coins(gasPrices);

  const account = await client.auth.accountInfo(senderAddress);
  const signerDataArray = [{
    address: senderAddress,
    publicKey: account.getPublicKey(),
    sequenceNumber: account.getSequenceNumber()
  }];

  const txFee = await client.tx.estimateFee(signerDataArray, { msgs: messages, gasPrices: gasPricesCoins, gasAdjustment: gasAdjustment, feeDenoms: ['uluna'] });
  
  const txdata : ExtensionOptions = {
      msgs: messages,
      isClassic: isClassic,
  };

  console.log(gasPrices, txdata, txFee);

  txdata.fee = new Fee(txFee.gas_limit, txFee.amount.add(new Coin('uluna', tax)));
  return txdata;
}

async function waitForInclusionInBlock(lcd: LCDClient, txHash: string): Promise<TxInfo | undefined> {
  let res;
  for (let i = 0; i <= 50; i += 1) {
    try {
      res = await lcd.tx.txInfo(txHash);
    } catch (error) {
      // NOOP
    }
      
    if (res) {
      break;
    }
      
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
      
  return res;
};

export interface NFTResponse {
  access: object,
  info: object,
  [k: string]: unknown;
}
export interface StakingResponse {
  token_id: string,
  rarity_class: string,
  rewards: number,
  staked_since: number,
  unstaking_since: number,
  unstaking_at: number,
  withdrawals: object,
  withdrawn_amount: number,
  [k: string]: unknown;
}
export interface NFTShortResponse {
  owner: String,
  image: String,
  token_id: String,
  name: String,
  [k: string]: unknown;
}
export type ExecuteMsg = {
  burn: {
    coins: number,
    [k: string]: unknown;
  };
};
export type NFT = {
  access: {
    owner?: string,
    approvals?: object
  },
  info: {
    extension?: {
      image: string,
      name: string,
      description: string,
      current_status: string
    }
  }
};
export type Chain = {
  id: Number,
  name: string,
  chainId: string,
  lcdUrl: string,
  fcdUrl: string,
  isClassic: boolean
};
export type Addr = string;
export type QueryMsg = {
  Burned: {
    [k: string]: unknown;
  };
};
export interface State {
  burned: number;
  owner: Addr;
  [k: string]: unknown;
}
export interface MiataNFTReadOnlyInterface {
  chain: Chain,
  getNFTOwnedCount: (contractAddress: string, owner?: string, collection?: number, legacy?: boolean) => Promise<any>;
  getNFT: (token_id: string,  contractAddress: string) => Promise<NFTResponse>;
  getNFTMultiple: (token_ids: string[],  contractAddress: string) => Promise<NFTResponse>;
  getNFTs: (owner: string | null, contractAddress: string, isLegacy?: boolean, selling?: boolean, sortby?: string, page?: number) => Promise<NFTShortResponse[]>;
  getNFTIds: (owner: string | null, contractAddress: string, start_after?: string) => Promise<NFTShortResponse[]>;
  getRemainingEggs: (hatcheryContract: string) => Promise<any>;
  getEggs: (owner: string, hatcheryContract: string) => Promise<any>;
  getEgg: (egg_id: string, hatcheryContract: string) => Promise<any>;
  getMatingInfo: (wallet: string, hatcheryContract: string, penguin: string, penguin2: string) => Promise<any>;
  getHatchFees: (hatcheryContract: string) => Promise<any>;
  getClans: (contractAddress: string) => Promise<any>;
  getBalance: (address: string) => Promise<any>;
  getCw20Balance: (address: string, contractAddress: string) => Promise<any>;
  getDelegated: (address: string) => Promise<any>;
  getAllDelegated: (address: string) => Promise<any>;
  //getFreeLeaves: (token_id: string, contractAddress: string) => Promise<any>;
  getRewards: (address: string, contractAddress: string, clan: string) => Promise<any>;

  getGrants: (walletAddress: string, managerWallet: string ) => Promise<any>;
  getStakingRewards: (walletAddress: string ) => Promise<any>;
  getAutocompoundStatus: (contract: string, walletAddress: string ) => Promise<any>;
}
export class MiataNFTQueryClient implements MiataNFTReadOnlyInterface {
  chain: Chain;
  client: LCDClient;

  constructor(client: LCDClient, chain: Chain) {
    this.chain = chain;
    this.client = client;
    this.getNFTOwnedCount = this.getNFTOwnedCount.bind(this);
    this.getNFT = this.getNFT.bind(this);
    this.getNFTs = this.getNFTs.bind(this);
    this.getNFTIds = this.getNFTIds.bind(this);
    this.getRemainingEggs = this.getRemainingEggs.bind(this);
    this.getEggs = this.getEggs.bind(this);
    this.getEgg = this.getEgg.bind(this);
    this.getMatingInfo = this.getMatingInfo.bind(this);
    this.getHatchFees = this.getHatchFees.bind(this);
    this.getClans = this.getClans.bind(this);
    this.getBalance = this.getBalance.bind(this);
    this.getCw20Balance = this.getCw20Balance.bind(this);
    this.getDelegated = this.getDelegated.bind(this);
    this.getAllDelegated = this.getAllDelegated.bind(this);
    //this.getFreeLeaves = this.getFreeLeaves.bind(this);
    this.getRewards = this.getRewards.bind(this);

    this.getGrants = this.getGrants.bind(this);
    this.getStakingRewards = this.getStakingRewards.bind(this);
    this.getAutocompoundStatus = this.getAutocompoundStatus.bind(this);
  }

  getBalance = async (address: string): Promise<any> => {
    console.log('getBalance', address);

    return this.client.bank.balance(address).catch((error) => console.log('getBalance error ', error));
  }

  getCw20Balance = async (address: string, contractAddress: string): Promise<any> => {
    console.log('getCw20Balance', address, contractAddress);

    return this.client.wasm.contractQuery(contractAddress, {
      balance: {
        address: address
      }
    }).catch((error) => console.log('getCw20Balance error ', error));
  }

  getDelegated = async (address: string): Promise<any> => {
    console.log('getDelegated', address);

    return this.client.staking.delegation(address, ValidatorAddress).catch((error) => console.log('getDelegated error ', error));
  }

  getAllDelegated = async (): Promise<any> => {
    console.log('getAllDelegated');

    return this.client.staking.validator(ValidatorAddress).catch((error) => console.log('getAllDelegated error ', error));
  }

 /* getFreeLeaves = async (token_id: string, contractAddress: string): Promise<any> => {
    console.log('getFreeLeaves', token_id, contractAddress);

    return this.client.wasm.contractQuery(contractAddress, {
      free_leaves: {
        token_id: token_id
      }
    }).catch((error) => console.log('getFreeLeaves error ', error));
  }
*/
  getRewards = async (address: string, contractAddress: string, clan: string): Promise<any> => {
    console.log('getRewards', address);

    return this.client.wasm.contractQuery(contractAddress, {
      owner_rewards: {
        owner: address,
        clan: clan
      }
    }).catch((error) => console.log('getRewards error ', error));
  }

  getNFTOwnedCount = async (mintContract: string, owner?: string, collection?: number, legacy?: boolean): Promise<any> => {
    console.log('getNFTOwnedCount', owner);

    if(!owner) {
      return {'count': 0};
    }

    if(legacy) {
      return {'count': 0};
    }
    
    return this.client.wasm.contractQuery(mintContract, {
      num_tokens_owned: {
        owner: owner,
        collection: collection
      }
    }).catch((error) => console.log('getNFTOwnedCount error ', error));
  };

  getNFT = async (token_id: string, mintContract: string): Promise<any> => {
    return this.client.wasm.contractQuery(mintContract, {
      all_nft_info: {
        token_id: token_id
      }
    }).catch((error) => console.log('getNFT error ', error));
  };
  
  getNFTMultiple = async (token_ids: string[], mintContract: string): Promise<any> => {
    return this.client.wasm.contractQuery(mintContract, {
      all_nft_info_multiple: {
        token_ids: token_ids
      }
    }).catch((error) => console.log('getNFTMultiple error ', error));
  };

  getMatingInfo = async (wallet: string, hatcheryContract: string, penguin: string, penguin2: string): Promise<any> => {
    return this.client.wasm.contractQuery(hatcheryContract, {
      mating_fee: {
        wallet: wallet,
        penguin_id: penguin,
        penguin_id2: penguin2
      }
    }).catch((error) => console.log('getMatingInfo error ', error));
  }
  
  getNFTs = async (owner: string | null, mintContract: string, isLegacy?: boolean, selling?: boolean, sortby?: string, page?: number): Promise<any> => {
    console.log('getNFTs', mintContract, owner);

    let start_after : string | number | undefined = undefined;
    if(page && page > 1) {
      if(owner) {
        start_after = ((page || 1) - 1) * 25;
      } else {
        let sort_by: any = undefined;
        let sort_dir: any = undefined;
        if(sortby === "priceasc") {
          sort_by = "PRICE";
          sort_dir = "ASC";
        } else if(sortby === "pricedesc") {
          sort_by = "PRICE";
          sort_dir = "DESC";
        }

        const res : {token: string} | undefined = await this.client.wasm.contractQuery(mintContract, {
          paged_token: {
            limit: 25,
            skip: (page || 1) - 1,
            selling: selling,
            sort_by: sort_by,
            sort_dir: sort_dir
          }
        });
        if(res && res.token) {
          start_after = res.token;
        }
      }
    }

    if(owner !== null) {
      if(isLegacy === true) {
        return this.client.wasm.contractQuery(mintContract, {
          tokens: {
            owner: owner
          }
        }).catch((error) => console.log('getNFTs error ', error));
      }
      
      return this.client.wasm.contractQuery(mintContract, {
        tokens_with_info: {
          owner: owner,
          limit: 25,
          start_after: start_after
        }
      }).catch((error) => console.log('getNFTs error ', error));
    }
     
    let sort_by: any = undefined;
    let sort_dir: any = undefined;
    if(sortby === "priceasc") {
      sort_by = "PRICE";
      sort_dir = "ASC";
    } else if(sortby === "pricedesc") {
      sort_by = "PRICE";
      sort_dir = "DESC";
    }

    return this.client.wasm.contractQuery(mintContract, {
      paged_tokens: {
        limit: 25,
        start_after: start_after,
        selling: selling,
        sort_by: sort_by,
        sort_dir: sort_dir
      }
    }).catch((error) => console.log('getNFTs error ', error));
  };  

  getNFTIds = async (owner: string | null, mintContract: string, start_after?: string): Promise<any> => {
    console.log('getNFTIds', mintContract, owner, start_after);
   
    if(owner !== null) {
      return this.client.wasm.contractQuery(mintContract, {
        tokens: {
          owner: owner,
          limit: 50,
          start_after: start_after
        }
      }).catch((error) => console.log('getNFTIds error ', error));
    }
     

    return this.client.wasm.contractQuery(mintContract, {
      all_tokens: {
        limit: 50,
        start_after: start_after,
      }
    }).catch((error) => console.log('getNFTIds error ', error));
  };
  
  getRemainingEggs = async (hatcheryContract: string): Promise<any> => {
    console.log('getRemainingEggs', hatcheryContract);

    return this.client.wasm.contractQuery(hatcheryContract, {
      egg_data: {}
    }).catch((error) => console.log('getRemainingEggs error ', error));
  };

  getEggs = async (owner: string, hatcheryContract: string): Promise<any> => {
    console.log('getEggs', hatcheryContract, owner);

    return this.client.wasm.contractQuery(hatcheryContract, {
      eggs: {
        owner: owner,
      }
    }).catch((error) => console.log('getEggs error ', error));
  };

  getEgg = async (egg_id: string, hatcheryContract: string): Promise<any> => {
    console.log('getEgg', hatcheryContract, egg_id);

    return this.client.wasm.contractQuery(hatcheryContract, {
      egg: {
        egg_id: egg_id,
      }
    }).catch((error) => console.log('getEgg error ', error));
  }

  getHatchFees = async (hatcheryContract: string): Promise<any> => {
    console.log('getHatchFees', hatcheryContract);

    return this.client.wasm.contractQuery(hatcheryContract, {
      hatching_fee: {}
    }).catch((error) => console.log('getHatchFees error ', error));
  }

  getClans = async (contractAddress: string): Promise<any> => {
    console.log('getClans', contractAddress);

    return this.client.wasm.contractQuery(contractAddress, {
      clans: {}
    }).catch((error) => console.log('getClans error ', error));
  }
  getGrants = async (walletAddress: string, managerWallet: string): Promise<any> => {
    console.log('getGrants', walletAddress);

    return this.client.authz.grants(walletAddress, managerWallet).catch((error) => console.log('getGrants error ', error));
  }

  getStakingRewards = async (walletAddress: string): Promise<any> => {
    console.log('getStakingRewards', walletAddress);

    return this.client.distribution.rewards(walletAddress).catch((error) => console.log('getStakingRewards error ', error));
  }

  getAutocompoundStatus = async (contract: string, walletAddress: string): Promise<any> => {
    console.log('getAutocompoundStatus', walletAddress);

    return this.client.wasm.contractQuery(contract, {
      Status: {
        address: walletAddress
      }
    }).catch((error) => console.log('getAutocompoundStatus error ', error));
  }
}

export interface MiataNFTInterface extends MiataNFTReadOnlyInterface {
  eatEgg: ( egg: string, hatcheryContract: string, ) => Promise<any>;
  hatchEgg: ( egg: string, hatcheryContract: string, fee: number) => Promise<any>;
  matePenguins: (penguin: string, penguin2: string, hatcheryContract: string, fee: number) => Promise<any>;
  joinClan: (token_id: string, clan: string, contractAddress: string, fee: number) => Promise<any>;
  leaveClan: (token_id: string, clan: string, contractAddress: string, fee: number) => Promise<any>;
  setClanFees: (clan: string, contractAddress: string, join_fee: number, leave_fee: number) => Promise<any>;
  claimRewards: (contractAddress: string, clans: string[]) => Promise<any>;
  delegate: (amount: number) => Promise<any>;

  grantStaking: (walletAddress: string, grantee: string, validator: string) => Promise<any>;
  revokeGrant: (walletAddress: string, grantee: string, msgtype: string) => Promise<any>;

  setAutoCompound: (contract: string, walletAddress: string, status: boolean) => Promise<any>;
  grantValidatorAuthz: (walletAddress: string, grantee: string, validators: string[]) => Promise<any>;
  revokeValidatorGrant: (walletAddress: string, grantee: string) => Promise<any>;
}

export class MiataNFTClient extends MiataNFTQueryClient implements MiataNFTInterface {
  client: LCDClient;
  wallet: Wallet | ConnectedWallet;

  constructor(client: LCDClient, wallet: Wallet | ConnectedWallet, chain: Chain) {
    super(client, chain);
    this.chain = chain;
    this.client = client;
    this.wallet = wallet;
    this.eatEgg = this.eatEgg.bind(this);
    this.hatchEgg = this.hatchEgg.bind(this);
    this.matePenguins = this.matePenguins.bind(this);
    this.joinClan = this.joinClan.bind(this);
    this.leaveClan = this.leaveClan.bind(this);
    this.setClanFees = this.setClanFees.bind(this);
    this.claimRewards = this.claimRewards.bind(this);

    this.grantStaking = this.grantStaking.bind(this);
    this.revokeGrant = this.revokeGrant.bind(this);

    this.delegate = this.delegate.bind(this);

    this.setAutoCompound = this.setAutoCompound.bind(this);
    this.grantValidatorAuthz = this.grantValidatorAuthz.bind(this);
    this.revokeValidatorGrant = this.revokeValidatorGrant.bind(this);
  }

  matePenguins = async (penguin: string, penguin2: string, hatcheryContract: string, fee: number): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('matePenguins', penguin, penguin2, hatcheryContract, fee);

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;

    let funds;
    if(fee > 0) {
      funds = new Coins([new Coin('uluna', Math.round(fee))]);
    } else {
      funds = new Coins([]);
    }

    const execMsg = [
      new MsgExecuteContract(senderAddress, hatcheryContract, {
        mate_penguins: {
          penguin_id: penguin,
          penguin_id2: penguin2
        }
      }, funds)
    ];

    const txdata = await getTransactionData(execMsg, senderAddress, funds, this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    } 

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  eatEgg = async (egg: string, hatcheryContract: string): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('eatEgg', egg, hatcheryContract);

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
      
    const funds = new Coins([]);

    const execMsg = [
      new MsgExecuteContract(senderAddress, hatcheryContract, {
        eat_egg: {
          egg: egg
        }
      })
    ];

    const txdata = await getTransactionData(execMsg, senderAddress, funds, this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  hatchEgg = async (egg: string, hatcheryContract: string, fee: number): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('hatchEgg', egg, hatcheryContract);

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
      
    const funds = new Coins([new Coin('uluna', Math.round(fee))]);

    const execMsg = [
      new MsgExecuteContract(senderAddress, hatcheryContract, {
        hatch_egg: {
          egg: egg
        }
      }, funds)
    ];

    const txdata = await getTransactionData(execMsg, senderAddress, funds, this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  joinClan = async (token_id: string, clan: string, contractAddress: string, fee: number): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('joinClan', token_id, clan, contractAddress);

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
      
    const funds = new Coins([new Coin('uluna', Math.round(fee))]);

    const execMsg = [
      new MsgExecuteContract(senderAddress, contractAddress, {
        join_clan: {
          token_id: token_id,
          clan: clan
        }
      }, funds)
    ];

    const txdata = await getTransactionData(execMsg, senderAddress, funds, this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  leaveClan = async (token_id: string, clan: string, contractAddress: string, fee: number): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('leaveClan', token_id, clan, contractAddress);

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
      
    const funds = fee > 0 ? new Coins([new Coin('uluna', Math.round(fee))]) : new Coins();

    const execMsg = [
      new MsgExecuteContract(senderAddress, contractAddress, {
        leave_clan: {
          token_id: token_id,
          clan: clan
        }
      }, funds)
    ];

    const txdata = await getTransactionData(execMsg, senderAddress, funds, this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  setClanFees = async (clan: string, contractAddress: string, join_fee: number, leave_fee: number): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('setClanFees', clan, contractAddress, join_fee, leave_fee);

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
      
    const funds = new Coins([]);

    const execMsg = [
      new MsgExecuteContract(senderAddress, contractAddress, {
        clan_settings: {
          clan: clan,
          join_fee: { native: { denom: "uluna", amount: (join_fee * 1000000).toFixed(0) } },
          //leave_fee: { native: { denom: "uluna", amount: (leave_fee * 1000000).toFixed(0) } }
        }
      }, funds)
    ];

    const txdata = await getTransactionData(execMsg, senderAddress, funds, this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  claimRewards = async (contractAddress: string, clans: string[]): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('claimRewards', contractAddress, clans);

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
      
    const funds = new Coins([]);

    const execMsg = clans.map((clan) => new MsgExecuteContract(senderAddress, contractAddress, {
        withdraw: {
          clan: clan
        }
      }, funds));

    const txdata = await getTransactionData(execMsg, senderAddress, funds, this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      console.log(tx);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    console.log(execTx);
    return this.client.tx.broadcast(execTx);
  }

  delegate = async (amount: number): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('delegate', amount);

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
      
    const funds = new Coin('uluna', Math.round(amount * 1000000).toFixed(0));

    const execMsg = [
      new MsgDelegate(senderAddress, ValidatorAddress, funds)
    ];

    const txdata = await getTransactionData(execMsg, senderAddress, new Coins(), this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  grantStaking = async (granter: string, grantee: string, validator: string): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('grantStaking', granter, grantee, validator);

    const messages = [
      new MsgGrantAuthorization(granter, grantee, new AuthorizationGrant(new StakeAuthorization(1, undefined, new StakeAuthorizationValidators([validator]) ), new Date(1893456000000))),
      new MsgGrantAuthorization(granter, grantee, new AuthorizationGrant(new GenericAuthorization('/cosmwasm.wasm.v1.MsgExecuteContract'), new Date(1893456000000))),
      new MsgGrantAuthorization(granter, grantee, new AuthorizationGrant(new GenericAuthorization('/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward'), new Date(1893456000000))),
    ];

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
   
    const txdata = await getTransactionData(messages, senderAddress, new Coins(), this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  revokeGrant = async (granter: string, grantee: string, msgtype: string): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('revokeGrant', granter, grantee, msgtype);

    const messages = [
      new MsgRevokeAuthorization(granter, grantee, msgtype)
    ];
    
    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
  
    const txdata = await getTransactionData(messages, senderAddress, new Coins(), this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    return this.client.tx.broadcast(execTx);
  }

  setAutoCompound = async (contract: string, walletAddress: string, status: boolean): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('setAutoCompound', contract, walletAddress, status);

    const messages = (status ?
      [new MsgExecuteContract(walletAddress, contract, {Enable: {}})]
      :
      [new MsgExecuteContract(walletAddress, contract, {Disable: {}})]);
    
    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
  
    const txdata = await getTransactionData(messages, senderAddress, new Coins(), this.client, this.chain.fcdUrl, this.chain.isClassic);

    console.log(txdata);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      console.log(tx);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    console.log(execTx);
    return this.client.tx.broadcast(execTx);
  }

  grantValidatorAuthz = async (walletAddress: string, grantee: string, validators: string[]): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('grantValidatorAuthz', walletAddress, grantee, validators);

    const messages = [
      new MsgGrantAuthorization(walletAddress, grantee, new AuthorizationGrant(new StakeAuthorization(1, undefined, new StakeAuthorizationValidators(validators) ), new Date(1893456000000))),
    ];

    if(!isConnectedWallet(this.wallet)) {
      alert('Granting staking authorization is not possible this way. Please connect your wallet.');
      return;
    }

    const senderAddress = this.wallet.walletAddress;
   
    const txdata = {
      msgs: messages,
      fee: new Fee(200000, new Coins([new Coin('uluna', 5800000)])),
      memo: '',
      accountNumber: 0,
      sequence: 0,
    };

    if(this.wallet.connectType !== ConnectType.WALLETCONNECT) {

      throw new Error('Only walletconnect is supported for this action');
     /* // we need to use arbritary data signing
      const account = await this.client.auth.accountInfo(senderAddress);
    
      try {
        txdata.accountNumber = account.getAccountNumber();
        txdata.sequence = account.getSequenceNumber();
        const publicKey = account.getPublicKey();
        if(!publicKey) {
          throw new Error('No public key found');
        }

        const signResult = await this.wallet.signBytes(Buffer.from(JSON.stringify({
          msgs: [
            {
              '@type': '/cosmos.authz.v1beta1.MsgGrant',
              grant: {
                authorization: {
                  '@type': '/cosmos.staking.v1beta1.StakeAuthorization',
                  allow_list: {
                    address: validators
                  },
                  authorization_type: 'AUTHORIZATION_TYPE_DELEGATE'
                },
                expiration: '2030-01-01T00:00:00Z'
              },
              grantee: grantee
            }
          ],
          fee: txdata.fee,
          memo: txdata.memo,
          accountNumber: txdata.accountNumber,
          sequence: txdata.sequence,
        })));

          console.log('signResult', signResult);
        const copyTx = new Tx(new TxBody(txdata.msgs, txdata.memo), new AuthInfo([new SignerInfo(
          publicKey,
          txdata.sequence,
          new ModeInfo(new ModeInfo.Single(SignMode.SIGN_MODE_DIRECT))
        )], txdata.fee || new Fee(0, new Coins())), [Buffer.from(signResult.result.signature).toString('base64')]);

        // Broadcasting the transaction
        let result;
        try {
          result = await this.client.tx.broadcast(copyTx);
          console.log('Transaction broadcasted successfully:', result);
        } catch (error) {
          console.error('Error broadcasting transaction:', error);
          throw error;
        }

        return waitForInclusionInBlock(this.client, result.txhash);
      } catch (error) {
        console.error('Error signing transaction:', error);
        throw error;
      }*/
    }

    const tx = await this.wallet.post(txdata);
    return waitForInclusionInBlock(this.client, tx.result.txhash);
  }

  revokeValidatorGrant = async (walletAddress: string, grantee: string): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    console.log('revokeValidatorGrant', walletAddress, grantee);

    const messages = [
      new MsgRevokeAuthorization(walletAddress, grantee, '/cosmos.staking.v1beta1.MsgDelegate')
    ];
    
    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
  
    const txdata = await getTransactionData(messages, senderAddress, new Coins(), this.client, this.chain.fcdUrl, this.chain.isClassic);

    console.log(txdata);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      console.log(tx);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    }

    const execTx = await this.wallet.createAndSignTx(txdata);
    console.log(execTx);
    return this.client.tx.broadcast(execTx);
  }

}