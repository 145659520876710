import { ConnectType, useWallet, WalletStatus } from '@terra-money/wallet-provider';
import React, { useState } from "react";

import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { ExitToApp } from '@material-ui/icons';

const ConnectWallet: React.FunctionComponent<{openModal: any, handleCloseModal: any, darkMode: boolean, handleConnectWallet: any, handleDisconnectWallet: any, walletAddress: any}> = ({
  openModal,
  handleCloseModal,
  darkMode,
  handleConnectWallet,
  handleDisconnectWallet,
  walletAddress
}) => {
  const {
    status,
    network,
    wallets,
    availableConnectTypes,
    availableConnections,
    connect,
    disconnect,
  } = useWallet();

  const [connectList, setConnectList] = useState(false);

  const toggleConnectList = () => {
    setConnectList(!connectList);
  }

  // console.log(availableConnections);
  return (
    <>
        {status === WalletStatus.WALLET_NOT_CONNECTED && (
          <>
          <li className='nav__list-item' style={{position: "relative"}}>
            <a aria-label="connect wallet" className="btn btn--outline " href="/#" onClick={(e) => {toggleConnectList(); e.preventDefault();}} tabIndex={0}>Connect your wallet</a>
            <ul className="wallet-connect-list" style={{display: (connectList ? "block" : "none"), position: "absolute", zIndex: 3, background: "transparent", top: "48px", right: "-25px"}}>
              {availableConnections.map(
                ({ type, name, icon, identifier = '' }) => (
                  (type !== ConnectType.READONLY) && (
                    <li className='nav__list-item' key={`connection-${type}${identifier}`}>
                      <div
                        onClick={() => { connect(type, identifier); /*handleConnectWallet();*/ }}
                        onKeyPress={() => {}}
                        role='button'
                        tabIndex={0}
                        className="btn btn--plain"
                      >
                        <img
                        src={icon}
                        alt={name}
                        style={{ width: '2rem', height: '2rem', verticalAlign: 'middle', marginRight: '.5rem' }}
                      />
                        <span
                          style={{ textTransform: "capitalize" }}
                        >
                          {name}
                        </span>
                      </div>
                    </li>
                  )
                ),
              )}
            </ul>
          </li>

          </>
        )}
        {status === WalletStatus.WALLET_CONNECTED && (
          <li className='nav__list-item'>
            <button type="button" className="btn btn--plain" onClick={() => disconnect()}><Tooltip title={wallets[0].terraAddress}><span style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "inline-block", maxWidth: "100px"}}>{wallets[0].terraAddress}</span></Tooltip> <span className="left-line">Exit <ExitToApp style={{verticalAlign: "middle", fontSize: "1rem"}} /></span></button>
          </li>
        )}
    </>
  );
}

export default ConnectWallet;