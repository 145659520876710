import uniqid from 'uniqid'
import LaunchIcon from '@material-ui/icons/Launch'
import './ProjectContainer.css'
import { Telegram, Twitter } from '@material-ui/icons'
import { Link } from 'react-router-dom'

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <h3>{project.name}</h3>

    <p className='project__description'>{project.description}</p>
    {project.stack && (
      <ul className='project__stack'>
        {project.stack.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {project.telegram && (
      <a
        href={project.telegram}
        aria-label='telegram'
        className='link link--icon'
      >
        <Telegram />
      </a>
    )}

    {project.twitter && (
      <a
        href={project.twitter}
        aria-label='twitter'
        className='link link--icon'
      >
        <Twitter />
      </a>
    )}

    {project.page && (
      <Link to={project.page} aria-label='live preview' className='link link--icon'>
        <LaunchIcon />
      </Link>
    )}
  </div>
)

export default ProjectContainer
