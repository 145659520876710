import { PictureAsPdf, YouTube } from '@material-ui/icons'
import './Projects.css'

const PenguinIsland = ({miataClients, walletAddress, connectedWallet}) => {
  return (
    <>
    <section id='penguinisland' className='section projects'>
    <h2 className='section__title'>
      <img src="/assets/img/penguin-island.jpg" alt="Penguin Island" />
    </h2>
    <p>More information coming soon …</p>
    <p>Watch the <a href='https://www.youtube.com/watch?v=-_tX44F4U6o' alt='Concept Trailer' target='_blank' rel="noreferrer"><YouTube style={{verticalAlign: "middle", fontSize: "1rem", marginBottom: "2px"}} /> Concept Trailer</a></p>
    </section>
    </>
  )
}

export default PenguinIsland
