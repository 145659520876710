import { Link } from 'react-router-dom'
import uniqid from 'uniqid'
import './Navbar.css'

const ProjectNav = ({ projects, toggleNavList }) => (
    <>
    {(projects && projects.length) && projects.map((project) => (
      <li className='nav__list-item' key={uniqid()} >
        <Link to={project.page} aria-label="call project page" className='link link--nav' onClick={toggleNavList}>{project.nav}</Link>
      </li>
    ))} 
    </>
)
export default ProjectNav
