import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import uniqid from 'uniqid'

import '../../App.css'

//import anime from "animejs";

const NftResultLayer = ({ info, openModal, handleClose, themeName }) => {

  return (
    <Modal
        sx={{ zIndex: 99000 }}
        className={`layer-modal auto-width ${themeName}`}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            className="layer-box"
          >
            <Typography
              color="secondary"
              variant="h6"
              component="div"
              mb={2}
            >
              <Typography variant="h6" component="span" mt={-0.2}>
                {(info && info.title) && info.title}
              </Typography>
            </Typography>
            
            <Box
              minHeight={100}
              >
                {(info && info.image) && (
                  <Box
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <img src={info.image} alt={info.title} style={{maxWidth: '100%'}} />
                  </Box>
                )}
            <Typography
              // This is global styles
              variant="body2"
              component="p"
              lineHeight={2}
              pr={2.5}
            >
              {(info && info.text) && info.text}
            </Typography>
            {(info && info.attributes) && (
              <Box
                sx={{
                  flexBasis: '100%',
                }}
              >
                {info.attributes.map((attribute, index) => (
                  <Box key={uniqid()} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ccc', padding: '0.5rem 0'}}> 
                    <Typography
                      // This is global styles
                      variant="body2"
                      component="p"
                      lineHeight={2}
                      pr={2.5}
                    >
                      {attribute.trait_type}
                    </Typography>
                    <Typography
                      // This is global styles
                      variant="body2"
                      component="p"
                      lineHeight={2}
                      pr={2.5}
                    >
                      {attribute.value}
                    </Typography>
                  </Box>
                ))}

              </Box>    
            )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: '1rem',
              }}
              >
              <a href="/#" className="btn btn--outline" onClick={(e) => { e.preventDefault(); handleClose();}} sx={{margin: '0 0 1.5rem'}}>
                Ok
              </a>
              </Box>
          </Box>
        </Fade>
      </Modal>
  );
};

export default NftResultLayer;
