import { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import { WalletStatus, createLCDClient, useConnectedWallet, useWallet } from '@terra-money/wallet-provider';

import { ThemeContext } from './contexts/theme'
import Header from './components/Header/Header'
import About from './components/About/About'
import Projects from './components/Projects/Projects'
// import Skills from './components/Skills/Skills'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'

import './App.css'
import Penguins from './components/Projects/Penguins';
import Hatchery from './components/Projects/Hatchery';
import LUNCBurn from './components/Projects/LUNCBurn';
import Error404 from './components/Error404';
import { MiataNFTClient } from './clients/MiataNFTClient.ts';
import PenguinIsland from './components/Projects/PenguinIsland';
import Clandom from './components/Projects/Clandom';
import Delegate from './components/Projects/Delegate';
import AuthZ from './components/Authz';

const App = () => {
  const [{ themeName }] = useContext(ThemeContext)
  const MiataApiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://api.miata.io';

  const {
    status,
    network,
    wallets,
    availableConnectTypes,
    availableConnections,
    connect,
    disconnect,
  } = useWallet();
  const lcdClients = {};
  const connectedWallet = useConnectedWallet();

  const [miataClients, setMiataClients] = useState();
  const [walletAddress, setWalletAddress] = useState("");

  useEffect(() => {
    const url = `${MiataApiUrl}/chains/active`;
    axios.get(url).then((usedChains) => {
      const newMiataClients = {};
      // init all lcds
      console.log(usedChains.data);
      usedChains.data.forEach((chain) => {
        newMiataClients[chain.chainId] = new MiataNFTClient(createLCDClient({
          lcdClientConfig: {
            isClassic: chain.isClassic
          }, network: {
            name: chain.name,
            chainID: chain.chainId,
            lcd: chain.lcdUrl
          }}), connectedWallet, chain);
      });
      setMiataClients(newMiataClients);
    });
    
    // console.log(status, wallets);
    setWalletAddress(status === WalletStatus.WALLET_CONNECTED ? wallets[0].terraAddress : '');
  }, [connectedWallet]);

  return (
    <div id='top' className={`${themeName} app`}>
      <BrowserRouter>
        <Header />

        <main>
          <Routes>
              <Route
                path="/"
                  element={
                    <>
                    {/*<About />*/}
                    <Projects />
                    {/*
                    <Skills />
                    */}
                    <Contact />
                    </>
                  }
              />
              <Route
                path="/luncpenguins"
                  element={
                    <>
                    <Penguins miataClients={miataClients} walletAddress={walletAddress} connectedWallet={connectedWallet} />
                    </>
                  }
              />
              <Route
                path="/clandom"  
                  element={
                    <>
                    <Clandom miataClients={miataClients} walletAddress={walletAddress} connectedWallet={connectedWallet}  />
                    </>
                  }
              />
             {/*<Route
                path="/luncburn"
                  element={
                    <>
                    <LUNCBurn miataClients={miataClients} walletAddress={walletAddress} connectedWallet={connectedWallet}  />
                    </>
                  }
                />*/}
              <Route
                path="/penguinisland"
                  element={
                    <>
                    <PenguinIsland miataClients={miataClients} walletAddress={walletAddress} connectedWallet={connectedWallet}  />
                    </>
                  }
              />
              <Route
                path="/delegate"
                  element={
                    <>
                    <Delegate miataClients={miataClients} walletAddress={walletAddress} connectedWallet={connectedWallet}  />
                    </>
                  }
              />
              <Route
                path="/authz"
                  element={
                    <>
                    <AuthZ miataClients={miataClients} walletAddress={walletAddress} connectedWallet={connectedWallet}  />
                    </>
                  }
              />
              <Route
                path="*"
                  element={
                    <>
                    <Error404 />
                    </>
                  }
              />

          </Routes>
        </main>

        <ScrollToTop />
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
