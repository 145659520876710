import { ImageSearchOutlined, PictureAsPdf, YouTube } from '@material-ui/icons'
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserDenied } from '@terra-money/wallet-provider';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import './Projects.css'
import './Hatchery.css'
import { ClandomContract, HatcheryContract, MiataApiUrl, PenguinCollection, PenguinContract, TokenContracts, getNFTData, getPreviewImage } from '../../utils/BackendApi';
import EggCard from './EggCard';
import NFTCard from './NFTCard';
import BroadcastLayer from '../../utils/Layers/BroadcastLayer';
import ErrorMessage from '../../utils/Layers/ErrorMessage';
import { ThemeContext } from '../../contexts/theme';
import NftResultLayer from '../../utils/Layers/NftResultLayer';

const Clandom = ({miataClients, walletAddress, connectedWallet}) => {
  const [{ themeName }] = useContext(ThemeContext)

  const [isLoaded, setIsLoaded] = useState(false);
  const [CardData, setCardData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [SelectedPenguin, setSelectedPenguin] = useState(null);
  const [SelectedClan, setSelectedClan] = useState(null);
  const [clans, setClans] = useState({});
  const [rewards, setRewards] = useState({});
  
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [isBroadcasting, setBroadcasting] = useState(false);
  const [modalText, setModalText] = useState("Please wait …");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorText, setErrorText] = useState("");
  const [openNftResult, setOpenNftResult] = useState(false);
  const [nftResult, setNftResult] = useState({});

  const handleOpenError = () => setOpenErrorMessage(true);
  const handleCloseError = () => setOpenErrorMessage(false);
  
  const handleOpenNftResult = () => setOpenNftResult(true);
  const handleCloseNftResult = () => setOpenNftResult(false);

  useEffect(() => {
    const fetchNftData = async () => {
      if(isLoaded) {
        setCardData([]);
        setClans({});
        setIsLoaded(false);
      }

      console.log('wallet is ', connectedWallet);
      if(!connectedWallet || !connectedWallet.network.chainID || !miataClients || !miataClients[connectedWallet.network.chainID] || !walletAddress) {
        setIsLoaded(true);
        return;
      }

      console.log('loading data for wallet ', walletAddress, ' on chain ', connectedWallet.network.chainID);


      // load OG penguins
      getNFTData(miataClients[connectedWallet.network.chainID], PenguinContract, walletAddress, true).then(async (allNfts) => {
        console.log('all nfts are ', allNfts);
        const nftList = allNfts.map((peng) => {
          return {
            token_id: peng.token_id,
            image_preview: peng.image_preview,
            name: peng.name,
            owner: peng.owner,
            clan: peng.attributes.find((att) => att.trait_type === 'Clan')?.value,
            position: peng.attributes.find((att) => att.trait_type === 'Role')?.value,
            rarity: peng.attributes.find((att) => att.trait_type === 'Rarity')?.value,
          };
        });

        setCardData(nftList);
        console.log('own og list is ', nftList);

        setIsLoaded(true);
      }).catch((err) => { return []; });

      miataClients[connectedWallet.network.chainID].getClans(ClandomContract).then((tmpres) => {
        console.log('clandom clansdata ', tmpres);
        if(tmpres) {
          console.log('clandom settings are ', tmpres);
          const new_clans = {};
          
            Promise.all(tmpres.map(async entry => {
              try {
                  /** @type Coins balanceResult */
                  const balanceResult = await miataClients[connectedWallet.network.chainID].getBalance(entry.settings.treasury);
                  const balance = balanceResult[0]?.get('uluna')?.amount || 0;
                  const balanceUST = balanceResult[0]?.get('uusd')?.amount || 0;
                  console.log('balance for ', entry.clan, balance, balanceUST);

                  const stakedResult = await miataClients[connectedWallet.network.chainID].getDelegated(entry.settings.treasury);
                  const staked = stakedResult?.balance?.amount || 0;

                  const cw20Promises = Object.keys(TokenContracts).map(async (token) => {
                      const cw20Result = await miataClients[connectedWallet.network.chainID].getCw20Balance(entry.settings.treasury, token);
                      return {
                          token: TokenContracts[token],
                          address: token,
                          balance: cw20Result?.balance?.amount || 0,
                      };
                  });
          
                  const cw20 = await Promise.all(cw20Promises);

                  console.log('cw20 for ', entry.clan, cw20);
                  new_clans[entry.clan] = {
                      token_id: entry.clan,
                      name: entry.name,
                      settings: entry.settings,
                      members: entry.members,
                      image_preview: `/assets/img/clandom/${entry.name.replace(/ /g, '')}.jpg`,
                      balance: parseInt(balance, 10) + parseInt(staked, 10),
                      balanceUST: parseInt(balanceUST, 10),
                      cw20
                  };
              } catch (err) {
                  console.error('Error fetching balance for entry', entry, err);
                  new_clans[entry.clan] = {
                      ...new_clans[entry.clan],
                      balance: 0,
                      balanceUST: 0,
                      cw20: [],
                  };
              }
          })).then(() => {
            console.log('clandom clans are ', new_clans);

            const clansArray = Object.values(new_clans);

            // Sort the array by balance in descending order
            clansArray.sort((a, b) => (b.balance + (b.balanceUST * 150)) - (a.balance + (a.balanceUST * 150)));

            // Convert the sorted array back into an object if you want to
            const sortedClans = {};
            clansArray.forEach(clan => {
                sortedClans[clan.token_id] = clan;
            });
            
            setClans(sortedClans);
          }).catch((err) => {
            console.log('error fetching balances ', err);
            setClans({});
          });
        }
      }).catch((err) => { 
        console.log(err);
        setClans({});
      });
  
    };
    fetchNftData();
  }, [connectedWallet, miataClients, reloadData])

  useEffect(() => {
    if(!connectedWallet || !connectedWallet.network.chainID || !miataClients || !miataClients[connectedWallet.network.chainID] || !walletAddress || !clans || Object.keys(clans).length < 1) {
      return;
    }

    console.log('loading data for wallet ', walletAddress, ' on chain ', connectedWallet.network.chainID);

    const new_rewards = {};
    Promise.all(Object.keys(clans).map(async clan_id => {
      const entry = clans[clan_id];
      try {
        const rewardResult = await miataClients[connectedWallet.network.chainID].getRewards(walletAddress, ClandomContract, clan_id);
        const got_rewards = rewardResult?.rewards || {};
        console.log('reward for ', entry.token_id, got_rewards);

        new_rewards[clan_id] = got_rewards;
      } catch (err) {
        console.error('Error fetching reward for entry', entry, err);
        new_rewards[clan_id] = {};
      }
    })).then(() => {
      console.log('clandom rewards are ', new_rewards);

      setRewards(new_rewards);
    }).catch((err) => {
      console.log('error fetching rewards ', err);
      setRewards({});
    });
  }, [clans]);

  const handleError = (error) => {
    setErrorTitle("Error");
    setErrorText("An error occured. Please try again.");
    console.log(typeof error, error);
    
    if(error instanceof UserDenied) {
      setErrorTitle("Transaction denied");
      setErrorText("The transaction was denied by the user.");
    } else if(typeof error === 'object') {
      if(error.response && error.response.data && error.response.data.code) {
        switch(error.response.data.code) {
          /*case 3:
            setErrorTitle("Insufficient Funds");
            setErrorText("You do not have enough funds to perform this transaction. Please also make sure you have enough funds in your wallet to cover the transaction fee.");
            break;*/
          case 5:
            setErrorTitle("Wallet not found");
            setErrorText("The connected wallet could not be found. Please be aware that a wallet only really exists once it has made its first transaction.");
            break;
          default:
            if(error.toString() === '[UserDenied]' || error.toString() === '[WebExtensionUserDenied]') {
              setErrorTitle("Transaction denied");
              setErrorText("The transaction was denied by the user.");
            } else {
              setErrorText(error.response.data.message);
            }
        }
      } else if(error.toString() === '[UserDenied]' || error.toString() === '[WebExtensionUserDenied]') {
        setErrorTitle("Transaction denied");
        setErrorText("The transaction was denied by the user.");
      } else {
        setErrorText(error.toString());
      }
    } else {
      setErrorText(error);
    }
    handleOpenError();
  }

  const handleSelect = async (penguin, element) => {
    
    /*const status = await miataClients[connectedWallet.network.chainID].getFreeLeaves(penguin.token_id, HatcheryContract);
    console.log('free leaves are ', status);
    penguin.free_leaves = status?.free_leaves ? parseInt(status.free_leaves, 10) || 0 : 0;*/

    console.log('selecting penguin ', penguin, element);
    
    setSelectedPenguin(penguin);
  }

  const handleDeselect = async (penguin, element) => {
    console.log('deselecting penguin ', penguin);
    if(SelectedPenguin !== penguin) {
      console.log('not the same penguin');
      return;
    }
    setSelectedPenguin(null);
  }

  const handleSelectClan = async (clan, element) => {
    
    console.log('selecting clan ', clan, element);
    
    setSelectedClan(clan.token_id);
  }

  const handleDeselectClan = async (clan, element) => {
    console.log('deselecting clan ', clan);
    if(SelectedClan !== clan.token_id) {
      console.log('not the same clan');
      return;
    }
    setSelectedClan(null);
  }

  const handleJoin = async (clan) => {
    clan = clan.replace(/ /g, '');

    if(!connectedWallet) {
      setErrorTitle("Error");
      setErrorText('Please connect your wallet first.');
      handleOpenError();
      return;
    }
    
    console.log('joining with penguins ', SelectedPenguin);

    if(!SelectedPenguin) {
      setErrorTitle("Error");
      setErrorText('You have to select a penguins first.');
      handleOpenError();
      return;
    }

    if(!clans[clan]) {
      setErrorTitle("Error");
      setErrorText('Could not load the clan settings. Please try again.');
      handleOpenError();
      return;
    }

    setModalText(`Trying to join the clan …`);
    setBroadcasting(true);
    miataClients[connectedWallet.network.chainID].joinClan(SelectedPenguin.token_id, clan, ClandomContract, clans[clan]?.settings?.join_fee?.native?.amount).then(async (result) => {
      console.log('joining result is ', result);
      const evts = result.logs[0].events;
      
      setSelectedPenguin(null);
      setSelectedClan(null);
      setReloadData(!reloadData);
    }).catch((error) => {
      handleError(error);
    }).finally(() => {
      setBroadcasting(false);
    });
  }

  const handleLeave = async () => {
    if(!connectedWallet) {
      setErrorTitle("Error");
      setErrorText('Please connect your wallet first.');
      handleOpenError();
      return;
    }
    
    console.log('joining with penguins ', SelectedPenguin);

    if(!SelectedPenguin) {
      setErrorTitle("Error");
      setErrorText('You have to select a penguins first.');
      handleOpenError();
      return;
    }

    const clan = SelectedPenguin.clan.replace(/ /g, '');
    if(!clans[clan]) {
      console.log(clans, clan);
      setErrorTitle("Error");
      setErrorText('Could not load the clan settings for ' + clan + '. Please try again.');
      handleOpenError();
      return;
    }

    setModalText(`Trying to leave the clan …`);
    setBroadcasting(true);
    miataClients[connectedWallet.network.chainID].leaveClan(SelectedPenguin.token_id, clan, ClandomContract, 0/*SelectedPenguin.free_leaves < 1 ? 0 : clans[clan]?.settings?.leave_fee?.native?.amount*/).then(async (result) => {
      console.log('joining result is ', result);

      setSelectedPenguin(null);
      setSelectedClan(null);
      setReloadData(!reloadData);
    }).catch((error) => {
      handleError(error);
    }).finally(() => {
      setBroadcasting(false);
    });
  }

  const handleClaimRewards = async (e, clan) => {
    if(!connectedWallet) {
      setErrorTitle("Error");
      setErrorText('Please connect your wallet first.');
      handleOpenError();
      return;
    }

    if(!clan) {
      // build list of all clans with rewards
      const rewardClans = Object.keys(rewards).filter((claim_clan) => {
        return (rewards[claim_clan] && Object.keys(rewards[claim_clan]).length > 0);
      });

      if(rewardClans.length < 1) {
        setErrorTitle("Error");
        setErrorText('You do not have any rewards to claim.');
        handleOpenError();
        return;
      }

      console.log('reward clans are ', rewardClans);
      console.log('claiming all rewards');
      setModalText(`Trying to claim all rewards …`);
      setBroadcasting(true);
      miataClients[connectedWallet.network.chainID].claimRewards(ClandomContract, rewardClans).then(async (result) => {
        console.log('claiming result is ', result);
        
        setReloadData(!reloadData);
      }).catch((error) => {
        handleError(error);
      }).finally(() => {
        setBroadcasting(false);
      });
    } else {
      console.log('claiming rewards for ', clan);
      setModalText(`Trying to claim rewards for ${clan} …`);
      setBroadcasting(true);
      miataClients[connectedWallet.network.chainID].claimRewards(ClandomContract, [clan]).then(async (result) => {
        console.log('claiming result is ', result);

        setReloadData(!reloadData);
      }).catch((error) => {
        handleError(error);
      }).finally(() => {
        setBroadcasting(false);
      });
    }
  }
     

  const randomInt = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /*if (2 * 2 === 4) {

  return (
    <section id='clandom' className='section projects'>
      Disabled for now.
    </section>
  );
  }
*/
  return (
    <>
    <BroadcastLayer title="Broadcasting…" text={modalText} openModal={isBroadcasting} themeName={themeName} />
    <ErrorMessage title={errorTitle} text={errorText} openModal={openErrorMessage} handleClose={handleCloseError} themeName={themeName} />
    <NftResultLayer info={nftResult} openModal={openNftResult} handleClose={handleCloseNftResult} themeName={themeName} />
    <section id='clandom' className='section projects'>
      <Tabs defaultActiveKey="clans" id="clandom-tabs">
        <Tab eventKey="clans" title="Clans">
          <h2 className='section__title'>The Clans</h2>
          {(isLoaded && Object.keys(clans).length > 0) ? (
            <>
            <div className='section__content nft-section centered mating'>
              {Object.keys(clans).map((clan, index) => (
                <>
                <div className="nft-card" key={`clan-${clan}`}>
                    <div className='nft-card__image'>
                        <img src={clans[clan].image_preview} alt={clan} />
                    </div>
                    <div className='nft-card__content'>
                        <h3 className='nft-card__title'>{clans[clan].name}</h3>
                        <small>{clans[clan].members || "No"} Members</small><br />
                        <br />
                        <small>Join: {Math.floor(clans[clan].settings?.join_fee?.native?.amount / 1000000).toLocaleString(undefined, {maximumFractionDigits: 6})} LUNC</small><br />
                        {/*<small>Leave: {Math.floor(clans[clan].settings?.leave_fee?.native?.amount / 1000000).toLocaleString(undefined, {maximumFractionDigits: 6})} LUNC</small>*/}
                        {(CardData.find((peng) => { return (peng.clan?.replace(/ /g, '') === clan && peng.position === "Leader");})) && (
                          <div className="nft-card__actions">
                            <h3 className='nft-card__title'>Set Fees</h3>
                            <div className="nft-card__actions__item">
                              <label htmlFor={`clan-${clan}-join-fee`}>Join Fee
                              <input type="number" id={`clan-${clan}-join-fee`} name={`clan-${clan}-join-fee`} placeholder="Join Fee" defaultValue={Math.floor(clans[clan].settings?.join_fee?.native?.amount / 1000000).toLocaleString(undefined, {maximumFractionDigits: 6})} /></label>
                            </div>
                            {/*<div className="nft-card__actions__item">
                              <label htmlFor={`clan-${clan}-leave-fee`}>Leave Fee 
                              <input type="number" id={`clan-${clan}-leave-fee`} name={`clan-${clan}-leave-fee`} placeholder="Leave Fee" defaultValue={Math.floor(clans[clan].settings?.leave_fee?.native?.amount / 1000000).toLocaleString(undefined, {maximumFractionDigits: 6})} /></label>
                            </div>*/}
                            <div className="nft-card__actions__item">
                              <button type="button" className="btn btn--outline" onClick={() => {
                                const join_fee = document.getElementById(`clan-${clan}-join-fee`).value;
                                //const leave_fee = document.getElementById(`clan-${clan}-leave-fee`).value;
                                console.log('setting fees to ', join_fee/*, leave_fee*/);
                                setModalText(`Setting fees …`);
                                setBroadcasting(true);
                                miataClients[connectedWallet.network.chainID].setClanFees(clan, ClandomContract, join_fee, 0/*leave_fee*/).then(async (result) => {
                                  console.log('setting fees result is ', result);
                                  const evts = result.logs[0].events;
                                  
                                  setReloadData(!reloadData);
                                }).catch((error) => {
                                  handleError(error);
                                }).finally(() => {
                                  setBroadcasting(false);
                                });
                              }}>Set Fees</button>
                            </div>
                          </div>
                        )}
                        {/* <p className='nft-card__text'>{card.description}</p> */}
                    </div>
                </div>
                </>
              ))}
            </div>
            </>
          ) : (
            <p>Please wait while we load the clans.</p>
          )}
        </Tab>
        <Tab eventKey="mating" title="Manage your Penguins">
          <h2 className='section__title'>Manage your Penguins</h2>
          <p>Welcome to Clandom, Penguin enthusiasts. Join your Clan!</p>
          {(isLoaded && SelectedPenguin) ? (
            <>
            <div className='section__content nft-section centered mating'>
              <NFTCard card={SelectedPenguin} collection={PenguinCollection} key={`card-${SelectedPenguin.token_id}`} hatchable handleSelect={handleSelect} handleDeselect={handleDeselect} selected />
              {(SelectedPenguin.clan) ? (
                <div>
                <button type="button" className="btn btn--outline" onClick={handleLeave}>Leave Clan{/*<br /><small>{(SelectedPenguin.free_leaves < 1 ? 0 : (clans[SelectedPenguin.clan]?.settings?.leave_fee?.native?.amount / 1000000)).toLocaleString()} LUNC</small>*/}</button>
              </div>
              ) : (
                <>
                {(SelectedClan) ? (
                  <>
                  <NFTCard card={clans[SelectedClan]} collection={PenguinCollection} key={`card-${SelectedClan}`} hatchable handleSelect={handleSelectClan} handleDeselect={handleDeselectClan} selected />
                  <div>
                    <button type="button" className="btn btn--outline" onClick={() => {handleJoin(SelectedClan)}}>Join Clan<br /><small>{(clans[SelectedClan]?.settings?.join_fee?.native?.amount / 1000000).toLocaleString()} LUNC</small></button>
                  </div>
                  </>
                ) : (
                  <>
                  <h3>Join a Clan</h3>
                  <div className='section__content nft-section mating-selection'>
                  {isLoaded ? (
                    Object.keys(clans).map((clan, index) => (
                      <div className={(clan === SelectedClan) ? "nft-card hatchable selected" : "nft-card hatchable"}>
                        <div className='nft-card__image'>
                            <a href="/#" onClick={(e) => { e.preventDefault(); if(clan === SelectedClan) { handleDeselectClan(clans[clan], e.target); } else { handleSelectClan(clans[clan], e.target); } return false;}} target="_blank" rel="noreferrer"><img src={clans[clan].image_preview} alt={clans[clan].name} /></a>
                        </div>
                        <div className='nft-card__content'>
                            <h3 className='nft-card__title'>{clans[clan].name}</h3>
                            <small>{clans[clan].members} members</small>
                            {/* <p className='nft-card__text'>{card.description}</p> */}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className='nft-card__text'>Please wait while we load the Clans.</p>
                  )}
                  </div>
                  </>
                )}
                </>
              )}
            </div>
            </>
          ) : (
            <div className='section__content nft-section'>
              Come, select a Penguins to manage!
            </div>
          )}
          <h2 className='section__title'>Your Penguins</h2>
          <div className='section__content nft-section mating-selection'>
          {isLoaded ? (
            CardData.length > 0 ? (
              CardData.map((card, index) => (
                <NFTCard card={card} collection={PenguinCollection} key={`card-${card.token_id}`} hatchable handleSelect={handleSelect} handleDeselect={handleDeselect} selectedItems={SelectedPenguin ? [SelectedPenguin] : null} />
              ))
            ) : (
              <div className='plain-card'>
              <div className='nft-card__content'>
              <h3 className='nft-card__title'>No Penguins found</h3>
              <p className='nft-card__text'>You don&apos;t seem to have any Penguins yet. Please connect your wallet or check back later. You might want to buy some from the <a href={`https://miata.io/creators/2/collection/${PenguinCollection}/marketplace`} target="_blank" rel="noreferrer" style={{whiteSpace: "nowrap"}}><ImageSearchOutlined style={{verticalAlign: "baseline", fontSize: "1rem"}} /> Marketplace on Miata.io</a></p>
              </div>
              </div>
            )
          ) : (
            <div className='plain-card'>
            <div className='nft-card__content'>
            <h3 className='nft-card__title'>Loading...</h3>
            <p className='nft-card__text'>Please wait while we load your Penguins.</p>
            </div>
            </div>
          )}
          </div>
        </Tab>
        <Tab eventKey="stats" title="Statistics">
          <h2 className='section__title'>Rewards and Toplist</h2>

          {(isLoaded && Object.keys(rewards).filter((clan, index) => rewards[clan] && Object.keys(rewards[clan]).length > 0).length > 0) ? (
            <>
            <TableContainer component={Paper}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell valign='middle' />
                    <TableCell valign='middle'>Clan</TableCell>
                    <TableCell valign='middle'>Rewards</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                {Object.keys(rewards).filter((clan, index) => rewards[clan] && Object.keys(rewards[clan]).length > 0).map((clan, index) => (
                  <TableRow key={`clanrew-${clan}`}>
                    <TableCell valign='middle'><img src={clans[clan].image_preview} alt={clan} width="30" /></TableCell>
                    <TableCell valign='middle'><strong>{clans[clan].name}</strong></TableCell>
                    <TableCell valign='middle'>
                      {(rewards[clan] && Object.keys(rewards[clan]).length > 0) ? (
                        <>
                        {Object.keys(rewards[clan]).map((creward, cindex) => (
                          <>
                          {cindex > 0 && (<br />)}
                          {(rewards[clan][creward][0] / 1000000).toLocaleString()} {
                            (() => {
                              if(creward === 'uluna') {
                                return 'LUNC';
                              }
                              
                              if(creward === 'uusd') {
                                return 'USTC';
                              }
                              
                              if(TokenContracts[creward]) {
                                return TokenContracts[creward];
                              }
                              
                              return creward + '…'
                            })()
                          }
                          </>
                        ))}
                        </>
                      ) : (
                        <>
                        No Rewards
                        </>
                      )}
                    </TableCell>
                    <TableCell valign='middle'><button type="button" className="btn btn--outline" onClick={(e) => handleClaimRewards(e, clan.clan)}>Claim</button></TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>
              <p><button type="button" className='btn btn--outline' onClick={handleClaimRewards}>Claim All Rewards</button></p>
            </TableContainer>
            </>
          ) : (
            <p>There are currently no rewards for you.</p>
          )}
          
          <h2 className='section__title'>Toplist</h2>
          <p>The active set (Top 3) will be rewarded at the end of the month. Price pool is 10,000 USTC (#1: 3,500 · #2 2,100 · #3 1,400).</p>
          {(isLoaded && Object.keys(clans).length > 0) && (
            <>
            <TableContainer component={Paper} sx={{mt: 4}}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell valign='middle'>#</TableCell>
                    <TableCell valign='middle' />
                    <TableCell valign='middle'>Clan</TableCell>
                    <TableCell valign='middle'>Balance</TableCell>
                    <TableCell valign='middle'>Members</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Object.keys(clans).map((clan, index) => (
                  <TableRow key={`clans-${clan}`} className={index < 3 ? "active-set" : ""}>
                    <TableCell valign='middle'><h3>{index + 1}</h3></TableCell>
                    <TableCell valign='middle'><img src={clans[clan].image_preview} alt={clan} width="30" /></TableCell>
                    <TableCell valign='middle'><strong>{clans[clan].name}</strong></TableCell>
                    <TableCell valign='middle'><Tooltip title={(clans[clan].cw20 && clans[clan].cw20.length > 0) && (
                        <>
                        {clans[clan].cw20.map((token, idx) => (
                          <>
                          {(idx > 0 ? <br /> : <></>)}
                          {(token.balance / 1000000).toLocaleString()} {token.token}
                          </>
                        ))}
                        </>
                      )}><span>{(clans[clan].balance / 1000000).toLocaleString()}<small> LUNC<br/>
                      {(clans[clan].balanceUST / 1000000).toLocaleString()} USTC
                      
                      </small></span></Tooltip></TableCell>
                    <TableCell valign='middle'>{clans[clan].members}</TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>
            </TableContainer>
            </>
          )}
        </Tab>
        <Tab eventKey="island" title="Penguin Island">
        <section id='penguinisland' className='section projects'>
          <h2 className='section__title'>
            <img src="/assets/img/penguin-island.jpg" alt="Penguin Island" />
          </h2>
          <p>More information coming soon …</p>
          <p>Watch the <a href='https://www.youtube.com/watch?v=-_tX44F4U6o' alt='Concept Trailer' target='_blank' rel="noreferrer"><YouTube style={{verticalAlign: "middle", fontSize: "1rem", marginBottom: "2px"}} /> Concept Trailer</a></p>
          </section>
        </Tab>
      </Tabs>
    </section>
    </>
  )
}

export default Clandom
