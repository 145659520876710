import { useEffect, useState } from 'react';
import { ImageSearchOutlined, PictureAsPdf } from '@material-ui/icons'
import './Projects.css'
import { PenguinContract, getNFTData } from '../../utils/BackendApi';
import NFTCard from './NFTCard';

const Penguins = ({miataClients, walletAddress, connectedWallet}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [CardData, setCardData] = useState([]);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    const fetchNftData = async () => {
      if(isLoaded) {
        setCardData([]);
        setIsLoaded(false);
      }

      console.log('wallet is ', connectedWallet);
      if(!connectedWallet || !connectedWallet.network.chainID || !miataClients || !miataClients[connectedWallet.network.chainID] || !walletAddress) {
        setIsLoaded(true);
        return;
      }

      console.log('loading data for wallet ', walletAddress, ' on chain ', connectedWallet.network.chainID);
      const nftList = await getNFTData(miataClients[connectedWallet.network.chainID], PenguinContract, walletAddress, false);
      console.log('list is ', nftList)

      setCardData(nftList);
      setIsLoaded(true);
    };
    fetchNftData();
  }, [connectedWallet, miataClients, reloadData])

  return (
    <>
    <section id='luncpenguins' className='section projects'>
    <h2 className='section__title'>
      <img src="/assets/img/luncpenguins.jpg" alt="LUNCPenguins NFT" />
    </h2>
    <p>LUNC Penguins are a collection of 1000 NFTs. The collection itself is based around the original concept of huddled penguins in an arctic storm, circling to conserve energy and heat.</p>
    <p>We always enjoyed bringing the community together on the idea of the 300 Spartans that held their ground at the Battle of Thermopylae.</p>
    <p>HCC FACTORY has hired <strong>Dr. Kipper</strong> to help resurrect the Spartan genome but his experiment did not go to plan!</p>
    <p><strong>MINTED OUT!</strong></p>
    <p>See the <a href='/assets/pdf/wp-luncpenguins.pdf' alt="Whitepaper" target='_blank'><PictureAsPdf style={{verticalAlign: "middle", fontSize: "1rem", marginBottom: "5px"}} /> Whitepaper</a></p>
    <p>Visit the <a href="https://miata.io/creators/2/collection/7/marketplace" target="_blank" rel="noreferrer"><ImageSearchOutlined style={{verticalAlign: "middle", fontSize: "1rem", marginBottom: "5px"}} /> Marketplace on Miata.io</a></p>
    </section>
    <section id='yournft' className='section projects'>
    <h2 className='section__title'>Your Penguins</h2>
      <div className='section__content nft-section'>
        {isLoaded ? (
          CardData.length > 0 ? (
            CardData.map((card, index) => (
              <NFTCard card={card} collection={7} key={`card-${card.token_id}`} />
            ))
          ) : (
            <div className='plain-card'>
            <div className='nft-card__content'>
            <h3 className='nft-card__title'>No Penguins found</h3>
            <p className='nft-card__text'>You don&apos;t have any Penguins yet. Please connect your wallet or check back later.</p>
            </div>
            </div>
          )
        ) : (
          <div className='plain-card'>
          <div className='nft-card__content'>
          <h3 className='nft-card__title'>Loading...</h3>
          <p className='nft-card__text'>Please wait while we load your Penguins.</p>
          </div>
          </div>
        )}
      </div>
    </section>
    </>
  )
}

export default Penguins
