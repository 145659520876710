import { createHash } from 'crypto'

export const MiataApiUrl = process.env.NODE_ENV === 'developmen' ? 'http://localhost:3001' : 'https://api.miata.io';
export const PenguinCollection = process.env.NODE_ENV === 'developmen' ? 316 : 7;
export const PenguinContract = process.env.NODE_ENV === 'developmen' ? 'terra1w4r2wjvt6tc4gkytykz09nj6qlwz2g448zqxzq' : 'terra1sc8tz0delrw32f58nhaxqj4k2gar9thnhlwpzx';
export const HatcheryContract = process.env.NODE_ENV === 'developmen' ? 'terra16sgqa94glk7dfwnn7jcg08hsfxsuzm5ppqt733' : 'terra12p9gr0wzzgxrt7tg3pxdw62z4np6yf9e2mrnhs';
export const LuncBurnContract = process.env.NODE_ENV === 'developmen' ? 'terra1v0mjvytwgrvj3u9epglthnk7w6y4zqn3jspxge' : 'terra1n5jh7c7ll7aejwhawgv6zng3sc28c6v2yj3jf9';
export const ClandomContract = process.env.NODE_ENV === 'developmen' ? 'terra152xrwhumux7mchcpp2mjdgq7cfw0y8pz98vk4lp8yvjepg8z28gsspy3ee' : 'terra1t4ef30kg5w84pt838khrw369zxv2rf0nx628eqrh02htf4rs2t3qaqjfnn';
export const ValidatorAddress = 'terravaloper15ahd0dg9qwkg5tjmkn7fm6sdrpwa47m50l4zrg';
export const ManagerWallet = 'terra13cwmpd4m3qxqkzhk7sqcahdsy84d5sfpkn9zm6';

export const TokenContracts = {
  "terra1e6snk4pn5y5t4c3qpy6y6aumhlqagzp9dem96zkg92dm4r57lm4s343nnd": "SGEM",
  "terra13lzs83suwmsznu0h62gc5urarmeal7k6ys2vypszjku20s54g2lqkwrhkl": "RGEM",
  "terra1ef5kyrf8f65lz2wwuhmxz6hph7ag5pm7xypyvccaezusf38az3tquc0ysg": "EGEM",
  "terra18jrgexnn35dxa8nrpmuatkxknmjs330gp6txavrp3fxzz6muztqqec60hf": "DGEM",
};

/*
  market: 'terra1nelf7rezl3plynhummq8jp7y8mmh4x6knpavrg',
  minting: 'terra1w4r2wjvt6tc4gkytykz09nj6qlwz2g448zqxzq',
  staking: 'terra1nqlt36f0qpdhhgj53rde4ysjgl74q7pc08s372'

  7383 on classic
  {"max_owner_usage": 20, "mint_contract": "terra18yqydec0u42dc00gl3j6twxl8ywqk5djcglqvx", "penguin_contract": "terra1sc8tz0delrw32f58nhaxqj4k2gar9thnhlwpzx", "treasury_wallet": "terra1g6pfwmu28zmj9r74kf3p55x2nlaw460rdx0hf3", "dna_fee": 1000000000, "admin": "terra1c6a2gsccd9endmc5u8vv9twqzavpamrtqv2553", "fee_wallet": "terra12lzf9lyd520uezrzwqwwvjer8lm8zdpg6elc7k", "hatch_price_common": 350000000000, "hatch_price_rare": 450000000000, "hatch_price_epic": 550000000000, "hatch_price_legendary": 650000000000 }

  {"init_config":{
    "identifier":"hatchery",
  "nft_contract": "terra1sc8tz0delrw32f58nhaxqj4k2gar9thnhlwpzx",
  "source_contract": null,
  "rare_source_contract": null,
  "reserved_source_contract": null,
  "staking_contract": "terra15hjnkg7gy2hw863y34570xxd0qup5nqhjyn66x",
  "scoring_contract": null,
  "rare_count": 0,
  "owner_wallet": "terra19uxzge89wnrkhmmeqk4zk8f9laq49j3x7zjdh0",
  "royalty_wallet": "terra19uxzge89wnrkhmmeqk4zk8f9laq49j3x7zjdh0",
  "fee_wallet": "terra12lzf9lyd520uezrzwqwwvjer8lm8zdpg6elc7k",
  "treasury_wallet": "terra1g6pfwmu28zmj9r74kf3p55x2nlaw460rdx0hf3",
  "burn_wallet": "terra1f6j6jcqjfk3gxg6kfd0v5ht782y625u349kqqz",
  "market_royalty": 200,
  "mint_fee": 250,
  "market_fee": 50,
  "treasury_share": 6500,
  "mint_burn_share": 1250,
  "market_burn_share": null,
  "market_treasury_share": 400,
  "boost_burn_share": null,
  "boost_treasury_share": null,
  "mint_price": 0,
  "mint_price_dollar": null,
  "mint_price_min": null,
  "mint_price_max": null,
  "mint_price_slippage": 500,
  "boost_price": null,
  "boost_price_increase": null,
  "boost_price_fee": null,
  "boost_limit": null,
  "mint_start": null,
  "mint_end": null,
  "generic": false,
  "generic_start_id": 1001,
  "mintable": false,
  "single_minter": "terra12p9gr0wzzgxrt7tg3pxdw62z4np6yf9e2mrnhs",
  "allow_send": true,
  "allow_burn": true,
  "is_swap": false,
  "use_pack": null,
  "swap_legacy": null,
  "swap_token_increment": 0,
  "swap_reserved_token_increment": 0
}}


cinst 438 '{"max_owner_usage": 20, "mint_contract": "terra1nelf7rezl3plynhummq8jp7y8mmh4x6knpavrg", "penguin_contract": "terra1w4r2wjvt6tc4gkytykz09nj6qlwz2g448zqxzq", "treasury_wallet": "terra1vxx7sr450ch43gkh6tz8nza072uh8f0ucur5hk", "dna_fee": 100000000, "admin": "terra1vxx7sr450ch43gkh6tz8nza072uh8f0ucur5hk", "fee_wallet": "terra1c6a2gsccd9endmc5u8vv9twqzavpamrtqv2553", "hatch_price_common": 3500000, "hatch_price_rare": 4500000, "hatch_price_epic": 5500000, "hatch_price_legendary": 6500000 }' --from vscode


  cexec terra1nelf7rezl3plynhummq8jp7y8mmh4x6knpavrg '{"init_config":{"identifier":"hatchery", "nft_contract":"terra1w4r2wjvt6tc4gkytykz09nj6qlwz2g448zqxzq","source_contract":null,"rare_source_contract":null,"reserved_source_contract":null,"staking_contract":"XXXXXXXXXXXXXXXXXXXXXXXX","scoring_contract":null,"rare_count":0,"owner_wallet":"terra1c6a2gsccd9endmc5u8vv9twqzavpamrtqv2553","royalty_wallet":"terra1c6a2gsccd9endmc5u8vv9twqzavpamrtqv2553","fee_wallet":"terra12lzf9lyd520uezrzwqwwvjer8lm8zdpg6elc7k","treasury_wallet":"terra1c6a2gsccd9endmc5u8vv9twqzavpamrtqv2553","burn_wallet":"terra1c6a2gsccd9endmc5u8vv9twqzavpamrtqv2553","market_royalty":400,"mint_fee":250,"market_fee":50,"treasury_share":7000,"mint_burn_share":1500,"market_burn_share":null,"market_treasury_share":200,"boost_burn_share":null,"boost_treasury_share":null,"mint_price":0,"mint_price_dollar":null,"mint_price_min":null,"mint_price_max":null,"mint_price_slippage":500,"boost_price":null,"boost_price_increase":null,"boost_price_fee":null,"boost_limit":null,"mint_start":null,"mint_end":null,"generic":false,"generic_start_id":1001,"mintable":false,"single_minter":"XXXXXXXXXXXXXXXXXXXXXXXX","allow_send":true,"allow_burn":true,"is_swap":false,"use_pack":null,"swap_legacy":null,"swap_token_increment":0,"swap_reserved_token_increment":0}}' --from vscode --fees 340000uusd

 --> egg data
 cexec terra16sgqa94glk7dfwnn7jcg08hsfxsuzm5ppqt733 '{"egg_data":{"data":""}}' --from vscode --fees 30000000uluna

*/
const getFromTraits = (traits, traitType) => {
    const trait = traits.find((item) => {
      return item.trait_type === traitType
    });
    if(trait) {
      return trait.value;
    }
    return null;
  };

  export const getNFTData = async (miataClient, contract, wallet, hatchery) => {
    const nft_list = [];
    
    let start_after = undefined;
  
    while(true) {
      console.log('Loading after ', start_after);
      const token_id_list = await miataClient.getNFTIds(wallet, contract, start_after).catch((err) => { console.log(err); return []; }).then((tmpres) => {
        if(tmpres && tmpres.tokens) {
          const token_ids = tmpres.tokens
            .filter((item) => { return (hatchery || parseInt(item, 10) <= 1000); });
          return token_ids;
        }
        return [];
      });
  
      console.log('token_id_list', token_id_list);
     
      if(token_id_list.length === 0) {
        break;
      }
  
      start_after = token_id_list[token_id_list.length - 1];
  
      const batchSize = 25;
      const promises = [];
      for (let i = 0; i < token_id_list.length; i += batchSize) {
          const batch = token_id_list.slice(i, i + batchSize);
      
          const promise = miataClient.getNFTMultiple(batch, contract).catch((err) => {
              console.error(err);
              return [];
          }).then((nft_data) => {
            if (nft_data.length > 0) {
              for (let j = 0; j < nft_data.length; j += 1) {
                  //console.log('loaded ', nft_data[j]);
                  const nft = nft_data[j].info.extension;
                  nft.token_id = batch[j];
                  nft.image_preview = getPreviewImage(nft.image);
                  nft.rarity = getFromTraits(nft.attributes, 'Rarity');
                  nft_list.push(nft);
              }
            }
          });
  
          promises.push(promise);
      }
  
      // Wait for all promises to complete
      await Promise.all(promises);
    }
    return nft_list;
  };
  

export function getPreviewImage(image) {
    if(!image) {
        return null;
    }
    const hash = createHash('sha256').update(image).digest('hex');
    if (image.endsWith('.gif')) {
        return 'https://miata.io/assets/preview/' + hash.substring(0, 1) + '/' + hash.substring(1, 3) + '/' + hash + '.gif';
    }
    
    return 'https://miata.io/assets/preview/' + hash.substring(0, 1) + '/' + hash.substring(1, 3) + '/' + hash + '.jpg';
}

export function shuffle(a) {
    let j;
    let x;
    let i;
    for (i = a.length - 1; i > 0; i -= 1) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}
